<template>
  <div v-if="mQuestion">
    <nav
      v-if="isLoggedIn && isInitiated"
      class="navbar-custom-menu"
    >
      <div class="navbar-item">
        <router-link
          :to="{ name: 'Dashboard'}"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Passport</span>
        </router-link>
      </div>
      <div class="navbar-item">
        <a
          @click="navigateBack"
          class="navbar-link icon-text is-arrowless"
        >
          <span class="icon is-small">
            <i class="fas fa-chevron-left"></i>
          </span>
          <span>Back</span>
        </a>
      </div>
    </nav>
    <hr />

    <div class="contentWrapper">
      <div class="columns is-multiline">
        <div class="column is-three-quarters">
          <article class="questionItemMedia media">
            <div class="media-left">
              <avatar-image
                class="is-64x64"
                :itemId="question.ProfileId"
                :imageSrc="question.ProfileImage"
                :itemName="question.ProfileName"
              />
            </div>
            <div class="media-content">
              <div class="content">
                <div class="has-text-grey postDetailBar">
                  <span
                    v-show="question.IsSolved"
                    class="has-text-success has-text-weight-bold has-margin-right"
                    style="display: none;"
                  >
                    <span class="icon">
                      <i class="fas fa-check-circle"></i>
                    </span>
                    <span>Solved</span>
                  </span>
                  <span class="date has-margin-right">
                    {{ question.CreatedOn | dateTimeStampToDate | longDateFormat }} -
                    {{ question.CreatedOn | timeFromDateTimeStamp }}
                  </span>
                  <span class="nrOfAnwsers">{{ question.Answers.length }} answers</span>
                </div>
                <div>
                  <a @click="showProfileDetail(question.ProfileId)">
                    <strong>{{ question.ProfileName }}:</strong>
                  </a>
                  <br />
                  <div
                    class="text-question"
                    :inner-html.prop="mQuestion.Text | nl2br"
                  ></div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="column is-one-quarter">
          <div class="has-text-weight-bold mb-3">Tags:</div>
          <div class="tags">
            <span
              v-for="(tag, index) in mQuestion.Tags"
              :key="index"
              class="tag is-outlined is-white"
            >{{ tag}}</span>
          </div>
        </div>

        <div
          v-if="isLoggedIn && question.ProfileId !== profile.Id"
          class="column is-full"
        >
          <transition
            name="slide-fade"
            mode="out-in"
            :duration="{ enter: 320, leave: 320 }"
          >
            <div
              v-if="!showAnswerInput"
              key="action"
            >
              <a
                @click="setShowAnswerInput()"
                class="button is-primary"
              >Give your answer</a>
            </div>

            <div
              key="answer-input"
              v-if="showAnswerInput"
            >
              <textarea
                class="textarea is-large has-fixed-size has-margin-bottom"
                v-model="answerText"
              />
              <button
                @click="cancelShowAnswerInput"
                :disabled="isSaving"
                class="button is-ghost mr-2"
              >Cancel</button>
              <button
                @click="sendAnswer"
                :disabled="isSaving"
                class="button is-primary"
                :class="{'is-loading' : isSaving}"
              >Send</button>
            </div>
          </transition>
        </div>

        <!-- <div
          v-if="!isLoggedIn"
          class="column is-full"
        >
          <div class="has-margin-top padding has-background-grey-light">
            <i class="font-icon s2m-icon-lock-big"></i>
            To be able to answer you need to be logged in...
            <router-link :to="{ name: 'Login'}">
              <span>Login</span>
            </router-link>
          </div>
        </div> -->

        <div class="column is-full column-questionAnswers">
          <div>
            <div
              class="is-size-3 has-text-weight-bold"
              v-text="question.Answers.length + (question.Answers.length === 1 ? ' answer' : ' answers')"
            ></div>
            <hr />
          </div>
          <hr class="separator" />
          <article
            v-for="answer in question.Answers"
            :key="answer.Id"
            class="media media-answers"
          >
            <div class="media-left has-text-centered">
              <avatar-image
                class="is-48x48 mb-6"
                :itemId="answer.ProfileId"
                :imageSrc="answer.ProfileImage"
                :itemName="answer.ProfileName"
              />
              <div
                v-if="answer.ProfileId !== profile.Id"
                class="vote-answerControl"
              >
                <question-vote
                  :answer="answer"
                  :profileId="profile.Id"
                />
              </div>
            </div>
            <div class="media-content">
              <div class="content">
                <div class="has-text-grey postDetailBar">
                  <a
                    v-if="!question.IsSolved && question.ProfileId === profile.Id"
                    @click="markAsSolution(answer.Id)"
                    class="button is-small link-check-answer is-primary has-margin-right"
                  >
                    <span class="icon">
                      <i class="check-answer fas fa-check"></i>
                    </span>
                    <span>Mark as best answer</span>
                  </a>
                  <span
                    v-if="answer.IsAnswer"
                    class="has-text-success has-text-weight-bold has-margin-right"
                  >
                    <i class="fas fa-check-circle"></i> Best Answer
                  </span>
                  <span class="date">
                    {{ answer.CreatedOn | dateTimeStampToDate | longDateFormat }} -
                    {{ answer.CreatedOn | timeFromDateTimeStamp }}
                  </span>
                </div>
                <div class>
                  <a @click="showProfileDetail(answer.ProfileId)">
                    <strong>{{ answer.ProfileName }}:</strong>
                  </a>
                  <div
                    class="text-answer mb-4"
                    :inner-html.prop="answer.Text | nl2br"
                  ></div>
                  <div>
                    <a
                      v-if="isLoggedIn && !showCommentInput"
                      @click="setShowCommentInput(answer)"
                      class="button is-small is-primary"
                    >
                      <span class="icon">
                        <i class="fas fa-reply"></i>
                      </span>
                      <span>Reply</span>
                    </a>
                    <div v-if="showCommentInput && selectedAnswer.Id === answer.Id">
                      <textarea
                        class="textarea has-margin-bottom"
                        v-model="commentText"
                      />
                      <a
                        @click="cancelShowCommentInput"
                        class="button is-ghost mr-2"
                      >Cancel</a>
                      <a
                        @click="sendComment"
                        class="button is-primary"
                      >Send</a>
                    </div>
                  </div>
                </div>
              </div>

              <comment-item
                v-for="comment in filterComments(answer, 0)"
                :key="comment.Id"
                :commentObj="comment"
                :comments="answer.Comments"
              ></comment-item>

              <article class="media">
                <div class="media-content">
                  <div class="content"></div>
                </div>
              </article>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import answerProvider from '../providers/answer'
import comment from '@/providers/comment'
import questionProvider from '../providers/question'
import QuestionVote from '@/components/partial/QuestionVote'

export default {
  components: {
    'question-vote': QuestionVote
  },
  data() {
    return {
      answerText: '',
      commentText: '',
      isSaving: false,
      mQuestion: this.$store.getters.getSelectedQuestion,
      selectedAnswer: null,
      showAnswerInput: false,
      showCommentInput: false
    }
  },
  computed: mapGetters({
    isInitiated: 'getIsInitiated',
    isLoggedIn: 'getIsLoggedIn',
    profile: 'getProfile',
    question: 'getSelectedQuestion',
    showUnsolvedOnly: 'getShowUnsolvedOnly'
  }),

  created() {
    window.scrollTo(0, 0)
  },

  mounted() {
    if (this.mQuestion === null) {
      this.getQuestionById()
    }
  },

  beforeDestroy() {
    this.$store.commit('setSelectedQuestion', null)
  },
  methods: {
    navigateBack() {
      window.history.length > 2
        ? this.$router.go(-1)
        : this.$router.push({ name: 'Questions' })
    },

    showProfileDetail(profileId) {
      this.$store.commit('setSelectedPublicProfile', {
        profileId: profileId,
        view: 'detail'
      })
    },

    getQuestionById() {
      questionProvider.methods
        .getQuestionById(this.$route.params.questionId)
        .then(response => {
          this.mQuestion = response.data
          this.$store.commit('setSelectedQuestion', response.data)
        })
    },

    markAsSolution(answerId) {
      answerProvider.methods.setBestAnswer(answerId).then(response => {
        let index = this.mQuestion.Answers.findIndex(a => a.Id === answerId)
        if (index > -1) {
          this.mQuestion.Answers[index].IsAnswer = true
          this.mQuestion.IsSolved = true

          this.$store.commit('setSelectedQuestion', this.mQuestion)
          this.$emit('markAsSolution', this.question.Id)
        }
      })
    },

    filterComments(answer, parentId) {
      return answer.Comments.filter(c => c.ParentId === parentId)
    },
    setShowAnswerInput() {
      this.showAnswerInput = true
    },
    setShowCommentInput(answer) {
      this.selectedAnswer = answer
      this.showCommentInput = true
    },
    cancelShowAnswerInput() {
      this.showAnswerInput = false
    },
    cancelShowCommentInput() {
      this.selectedAnswer = null
      this.showCommentInput = false
    },
    sendAnswer() {
      if (!this.isSaving) {
        this.isSaving = true
        answerProvider.methods
          .answerQuestion(this.question.Id, this.answerText)
          .then(response => {
            this.$store.commit('setSelectedQuestion', response.data)
            this.answerText = ''
            this.cancelShowAnswerInput()
          })
          .then(() => {
            this.isSaving = false
          })
      }
    },
    sendComment() {
      if (!this.isSaving) {
        this.isSaving = true
        let selectedAnswer = this.selectedAnswer
        comment.methods
          .addComment('answer', selectedAnswer.Id, this.commentText)
          .then(response => {
            let index = this.mQuestion.Answers.findIndex(
              a => a.Id === selectedAnswer.Id
            )
            if (index > -1) {
              this.mQuestion.Answers[index].Comments.push(response.data)
            }
          })
          .then(() => {
            this.isSaving = false
          })
      }

      this.commentText = ''
      this.showCommentInput = false
      this.selectedAnswer = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/template.scss";

.questionItemMedia {
  .media-content {
    width: 100%;
  }
  .postDetailBar {
    margin-bottom: 5px;
    .date {
      display: inline-block;
      margin-top: 2px;
      white-space: nowrap;
      @media screen and (max-width: 420px) {
        margin-top: 5px;
      }
    }
  }
  .nrOfAnwsers {
    white-space: nowrap;
  }
  .text-question {
    word-break: break-word;
    word-wrap: break-word;
  }
  .text-answer {
    margin-bottom: 10px;
    word-break: break-word;
  }
  a.is-link {
    color: $cyan;
    &:hover {
      text-decoration: underline;
    }
  }
  img {
    background-color: $grey-light;
  }

  .media-answers {
    font-size: 90%;
  }

  .vote-answerControl {
    display: none;
    strong {
      display: block;
      margin: -10px 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .image {
    &.is-64x64 {
      height: 42px;
      width: 42px;
    }
  }
  .media-answers {
    .image {
      &.is-48x48 {
        height: 32px;
        width: 32px;
      }
    }

    @media screen and (max-width: 420px) {
      .media-left {
        display: none;
      }
    }
  }
}
</style>
