<template>
  <div class="vote-wrapper">
    <div v-if="myVote">
      <span
        class="icon"
        :class="{'has-text-primary-dark': myVote.Vote > 0, 'has-text-grey-light': myVote.Vote < 0}"
      ><i class="font-icon s2m-icon-happiness"></i></span>
      <span
        class="icon"
        :class="{'has-text-primary-dark': myVote.Vote < 0, 'has-text-grey-light': myVote.Vote > 0}"
      ><i class="font-icon s2m-icon-indifferent"></i></span>
    </div>
    <div v-else>
      <a
        @click="upvote(answer.Id)"
        class="has-text-primary-dark"
        title="Answer is useful"
      >
        <span class="icon"><i class="font-icon s2m-icon-happiness"></i></span>
      </a>

      <a
        @click="downvote(answer.Id)"
        class="has-text-primary-dark"
        title="Answer is not useful"
      >
        <span class="icon"><i class="font-icon s2m-icon-indifferent"></i></span>
      </a>
    </div>

    <span class="scrore"><strong>Score:</strong> {{ answer.VoteScore }}</span>
  </div>
</template>

<script>
import answerProvider from '@/providers/answer'

export default {
  name: 'question-vote',

  props: {
    answer: null,
    profileId: {
      type: Number,
      default: 0
    },
    votes: {
      type: Array,
      default: function() { return [] }
    }
  },

  data() {
    return {
      mAnswer: this.answer,
      myVote: null
    }
  },

  created() {
    this.myVote = this.getVote()
  },

  methods: {
    getVote() {
      let self = this

      if (this.answer.Votes.length > 0) {
        let index = this.answer.Votes.findIndex(v => v.ProfileId === self.profileId)
        let output = []
        if (index !== -1) {
          return this.answer.Votes[index]
        }
      }
      return null
    },

    upvote(answerId) {
      let self = this
      answerProvider.methods.upvoteAnswer(answerId).then(response => {
        if (response.data) {
          self.updateAnswerScore(answerId, 1)
          self.answer.VoteScore = self.answer.VoteScore + 1
        }
      })
    },
    downvote(answerId) {
      let self = this
      answerProvider.methods.downvoteAnswer(answerId).then(response => {
        if (response.data) {
          self.updateAnswerScore(answerId, -1)
          self.answer.VoteScore = self.answer.VoteScore - 1
        }
      })
    },

    updateAnswerScore(answerId, value) {
      this.myVote = {
        AnswerId: answerId,
        CreatedOn: new Date().getTime(),
        Id: 0,
        ProfileId: 110,
        ProfileImage: '',
        ProfileName: '',
        Vote: value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scrore {
  font-size: 10px;
}
</style>
